<template>
    <div class="products">
        <!--- Modals --->
        <itc-modal :showModal="showModal.addCard">
            <div slot="header">
                <h4>Añadir Tarjeta</h4>
            </div>
            <div slot="body">
                <label>Nombre de la tarjeta</label>
                <input v-model="newCardName" type="text" class="form-control">
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="addNewCard">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.addCard = false">Cerrar</button>
                    </div>
            </div>
        </div>
        </itc-modal>
        <itc-modal :showModal="showModal.fee">
            <div slot="header">
                <h4>{{header}}</h4>
            </div>
            <div slot="body">
                <div class="row">
                    <div class="col-6">
                        <label>Numero de cuota</label>
                        <input v-model="newFee" type="number" class="form-control">
                    </div>
                    <div class="col-6">
                        <label>CTE</label>
                        <input v-model="newCte" type="number" class="form-control">
                    </div>
                </div>
            </div>
            <div slot="footer">
                <div class="row">
                    <div class="col">
                        <button class="modal-default-button btn btn-success float-right mx-2" @click="processSubmitFee">OK</button>
                        <button class="modal-default-button btn btn-warning float-right mx-2" @click="showModal.fee = false">Cerrar</button>
                    </div>
                </div>
            </div>
        </itc-modal>
        <!--- / Modals --->
        <h4 class="pt-3">Tarjetas<button @click="showModal.addCard = true" class="mx-2 btn btn-success btn-sm"><i class="fas fa-plus"></i></button></h4>
        <hr>
        <itc-card color="#343a40" class="custom-shadow w-50 align-div">
            <div slot="header" class="text-white text-center">
                <h4>Seleccionar tarjeta</h4>
            </div>
            <div slot="body" class=" w-50 align-div">
                <select v-model="card" class="form-control">
                    <option v-for="(card, i) in cards" :key="i" :value="card">{{card.name}}</option>
                </select>
            </div>
        </itc-card>
        <transition name="fade" mode="out-in">
            <div v-if="card.fees" class="table-resposive my-3 custom-shadow">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th>#Cuota</th>
                            <th>CTE</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-if="card.fees.length == 0" colspan="2" class="text-center">
                            <h5 class="p-4">Aún no hay cuotas para esta tarjeta</h5>
                        </tr>
                        <tr 
                            v-for="(fee, i) in card.fees" :key="i" class="card-item set-pointer"
                            @click="showFeeModal(fee, 'Editar cuota')">
                            <td>{{fee.fee}}</td>
                            <td>{{fee.cte}}</td>
                        </tr>
                        <tr>
                            <td class="add-fee" colspan="2">
                                <button @click="showFeeModal({fee: 0, cte: 0}, 'Añadir cuota')" class="btn btn-info float-right">Añadir</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div> 
        </transition>
    </div>
</template>

<script>
import axios from 'axios'

import * as utils from '@/assets/utils/utils.js'

import Modal from '@/components/mix/Modal'
import Card from '@/components/mix/Card'


export default {
    data(){
        return {
            showModal: {addCard: false, fee: false},
            newCardName: '',
            card: {},
            cards: [],
            header: '',
            newFee: 0,
            newCte: 0,
            feeToEdit: 0
        }
    },
    methods: {
        async processSubmitFee(){
            const findFee = fee => fee.fee == this.feeToEdit
            const index = this.card.fees.findIndex(findFee)
            const alreadyExists = index != -1
            if(alreadyExists) this.card.fees.splice(index, 1)
            var insertionIndex = utils.getInsertionIndex(this.card.fees,{fee: this.newFee, cte: this.newCte}, utils.compareFeesNumber)
            this.card.fees.splice(insertionIndex, 0, {fee: this.newFee, cte: this.newCte})
            this.showModal.fee = false
            await axios.post(`/card/${alreadyExists ? 'update' : 'add'}-fee`, {
                cardId: this.card._id,
                toEdit: this.feeToEdit,
                fee: this.newFee,
                cte: this.newCte
            })
        },
        showFeeModal(fee, header){
            this.feeToEdit = fee.fee
            this.newFee = fee.fee
            this.newCte = fee.cte
            this.header = header
            this.showModal.fee = true 
        },
        async addNewCard(){
            const resp = await axios.post('/card/add', {name: this.newCardName.toUpperCase()})
            this.card = resp.data
            this.showModal.addCard = false;
            const index = utils.getInsertionIndex(this.cards, this.newCardName, utils.compareCardNames)
            this.cards.splice(index, 0, this.card)
        }
    },
    components: {
        'itc-modal': Modal,
        'itc-card': Card
    },
    async created(){
        const resp = await axios.get('/card');
        this.cards = resp.data
    }   
}

</script>

<style lang="scss" scoped>
.add-fee{
    background-color: #343a40;
}
.card-item:hover{
    background-color: lightgreen;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>